/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { store } from '@/store';
import router from '@/router';
const axiosIns = axios.create({});

axiosIns.defaults.baseURL = '/api/';

axiosIns.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axiosIns.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
axiosIns.defaults.responseType = 'json';
axiosIns.defaults.transformRequest = [
    function (data, headers) {
        if (headers['Content-Type'] === 'multipart/form-data') {
            return data;
        } else {
            return qs.stringify(data);
        }
    }
];
axiosIns.defaults.validateStatus = function () {
    return true;
};
axiosIns.interceptors.request.use(function (config) {
    config.headers.setAccept('application/json');
    config.headers.System = 'vue';
    // config.headers = {
    //     ...config.headers,
    //     Accept: 'application/json',
    //     System: 'vue'
    // };
    if (store.state.isLogin) {
        config.headers.Token = store.state.userInfo.token;
    } else {
        store.commit('checkLogin');
        if (store.state.isLogin) {
            config.headers.Token = store.state.userInfo.token;
        }
    }
    return config;
});

axiosIns.interceptors.response.use(
    response => {
        const data = response.data;
        const status = response.status;

        if (status === 200 || status === 304) {
            const rstno = data.rstno;
            if (rstno > 0) {
                return data;
            } else {
                if (rstno === -401) {
                    store.commit('doLogout');

                    router.push({
                        path: '/user/login',
                        query: {
                            redirect: router.currentRoute.value.fullPath
                        }
                    });
                }
                return Promise.reject(data);
            }
        } else {
            if (status === 412) {
                store.commit('doLogout');

                router.push({
                    path: '/user/login',
                    query: {
                        redirect: router.currentRoute.value.fullPath
                    }
                });
            }

            return Promise.reject({
                rstno: -10000,
                data: {
                    msg: '网络错误',
                    errInfo: response
                }
            });
        }
    },
    error => {
        return Promise.reject({
            rstno: -10000,
            data: {
                msg: '网络错误',
                errInfo: error
            }
        });
    }
);

export function fileUpload<T> (...args:Parameters<typeof axiosIns.post >) {
    const [uri, data, config = { headers: {} }] = args;
    return new Promise<AxiosResponse<T, any>>(function (resolve, reject) {
        config.headers = {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        };

        axiosIns
            .post<T>(uri, data, config)
            .then(json => {
                resolve(json);
            })
            .catch(response => {
                reject(response);
            });
    });
}

export default axiosIns;
