import { InjectionKey, computed } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { AlbumModel, GenreStyleId, GenreStyleModel, GenreStyleType, UserId, UserModel, AlbumId } from '@/typings';

import { genreCatelog, LoginSuccessResponse } from '@/services';

export type AlbumCacheItem = Pick<AlbumModel, '_id' | 'title' | 'main_artist_show' | 'main_artist' | 'artists' | 'styles' | 'relate_styles' | 'links' | 'online_links' | 'primary_img'>

export interface State {
    loading_genre:boolean;
    genre_styles_cached: boolean;
    genre_styles:Record<string, Omit<GenreStyleModel, 'type'>& {
        type:GenreStyleType
    }>;
    genre_structure:Record<string, GenreStyleId[]>;
    isLogin:boolean;
    mainMenuActiveIndex:string;
    userInfo:{
        userId: UserId;
        name:string;
        token:string;
        type:UserModel['type']
    },
    albumCache:AlbumCacheItem[],
}

export const storeKey: InjectionKey<Store<State>> = Symbol('store key');

export const store = createStore<State>({
    state: {
        userInfo: {
            userId: '' as UserId,
            name: '',
            token: '',
            type: {
                index: 0,
                name: '用户'
            }
        },
        isLogin: false,

        loading_genre: false,
        genre_styles_cached: false,
        genre_styles: {},
        genre_structure: {
            0: []
        },

        mainMenuActiveIndex: 'dashboard',
        albumCache: []
    },
    mutations: {
        setGenreInfo (state, payload:Pick<State, 'genre_styles' | 'genre_structure'>) {
            state.genre_styles = payload.genre_styles;
            state.genre_structure = payload.genre_structure;
            state.genre_styles_cached = true;
        },
        setMenu (state, mainMenuActiveIndex:string) {
            state.mainMenuActiveIndex = mainMenuActiveIndex;
        },
        doLogout (state) {
            state.isLogin = false;

            state.userInfo.userId = '' as UserId;
            state.userInfo.name = '';
            state.userInfo.token = '';
            state.userInfo.type = {
                index: 0,
                name: '用户'
            };

            localStorage.removeItem('user_info');
            localStorage.removeItem('token_info');
        },
        doLoginToken (state, data:LoginSuccessResponse) {
            state.isLogin = true;
            state.userInfo.userId = data.user_info._id;
            state.userInfo.type = data.user_info.type;
            state.userInfo.name = data.user_info.name;
            state.userInfo.token = data.token_info.token;

            localStorage.setItem('user_info', JSON.stringify(data.user_info));
            localStorage.setItem('token_info', JSON.stringify(data.token_info));
        },
        checkLogin (state) {
            if (state.isLogin) {
                return;
            }
            const user_info = localStorage.getItem('user_info');
            if (!user_info) {
                state.isLogin = false;
                return;
            }
            const token_info = localStorage.getItem('token_info');
            if (!token_info) {
                state.isLogin = false;
                return;
            }
            const userInfo = JSON.parse(user_info);
            const tokenInfo = JSON.parse(token_info);

            if (!userInfo._id) {
                state.isLogin = false;
                return;
            }
            state.isLogin = true;

            state.userInfo.userId = userInfo._id;
            state.userInfo.name = userInfo.name;
            state.userInfo.token = tokenInfo.token;
            state.userInfo.type = userInfo.type;
        },
        addAlbumCache (state, item:AlbumCacheItem) {
            if (
                state.albumCache.find(album => {
                    return album._id === item._id;
                })
            ) {
                return;
            }
            const items = localStorage.getItem('albumCache');
            if (items) {
                state.albumCache = JSON.parse(items) || [];
            }
            state.albumCache.push(JSON.parse(JSON.stringify(item)));
            localStorage.setItem('albumCache', JSON.stringify(state.albumCache));
        },
        removeAlbumCacheById (state, id:AlbumId) {
            const items:AlbumCacheItem[] = JSON.parse(localStorage.getItem('albumCache') || '') || [];
            const index = items.findIndex(album => {
                return album._id === id;
            });
            if (index === -1) {
                return;
            }

            items.splice(index, 1);
            state.albumCache = items;
            localStorage.setItem('albumCache', JSON.stringify(state.albumCache));
        },
        removeAlbumCacheByIndex (state, index:number) {
            if (!state.albumCache[index]) {
                return;
            }
            state.albumCache.splice(index, 1);
            localStorage.setItem('albumCache', JSON.stringify(state.albumCache));
        },
        loadAlbumCache (state) {
            const items = localStorage.getItem('albumCache');
            if (items) {
                state.albumCache = JSON.parse(items) || [];
            }
        }

    },
    actions: {
        fetchCatelog ({ commit, state }, isForce = false) {
            if (!isForce) {
                const localStyles = localStorage.getItem('genre_styles');
                const localStructure = localStorage.getItem('genre_structure');
                if (localStyles && localStructure) {
                    commit('setGenreInfo', {
                        genre_styles: JSON.parse(localStyles),
                        genre_structure: JSON.parse(localStructure)
                    });
                    return;
                }
            }
            state.loading_genre = true;
            genreCatelog().then((res) => {
                commit('setGenreInfo', {
                    genre_styles: res.data.styles,
                    genre_structure: res.data.structure
                });
                localStorage.setItem('genre_styles', JSON.stringify(res.data.styles));
                localStorage.setItem('genre_structure', JSON.stringify(res.data.structure));
            }).finally(() => {
                state.loading_genre = false;
            });
        }
    },
    modules: {
    }
});

export function useStore () {
    return baseUseStore(storeKey);
}

export function useUserInfo () {
    const store = useStore();
    return computed(() => store.state.userInfo);
}
