import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/rank',
        name: 'Ranks',
        component: () => import('@/views/Rank/Ranks/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    },
    {
        path: '/rank/rank/:id',
        name: 'Rank',
        component: () => import('@/views/Rank/Rank.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    },
    {
        path: '/rank/version/write/:article_id',
        name: 'VersionWrite',
        component: () => import('@/views/Rank/VersionWrite/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    },
    {
        path: '/rank/version/:article_id',
        name: 'Version',
        component: () => import('@/views/Rank/Version.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    },
    {
        path: '/rank/version_item/:version_id/:article_id',
        name: 'VersionItem',
        component: () => import('@/views/Rank/VersionItem.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    }

];

export default routes;
