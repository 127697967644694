import ajax from '@/basics/fetch';
import { ArtistModel, AlbumModel, AlbumId, ArticleModel, ArtistId, GenreStyleId, Pages } from '@/typings';

export function getArtists (params:{
    page:number;
    sortKey:string;
    sortOrder: 'asc' | 'desc';
    genre:string
}) {
    return ajax.post<{
        artists:ArtistModel[];
        pages:{
            total:number;
        };
    }>('/meta/artists', params);
}

export function getAlbums (params:{
    page:number;
    sortKey?:string;
    sortOrder?: 'asc' | 'desc';
    genre?:string;
    filter?:{
        beginYear?:string | number;
        endYear?:string | number;
        rating_anon?:number;
    },
    sort?:string;
}) {
    return ajax.post<{
        albums:AlbumModel[];
        pages:Pages;
    }>('/meta/albums', params);
}

export function getAlbumInfo (ablumId:AlbumId) {
    return ajax.get<{
        info:AlbumModel;
        now_heart:number;
        rocknuts:ArticleModel[];
        rocknuts_pages:{
            total:number;
            pageNow:number;
            perPage:number;
        }
    }>(`/meta/album/${ablumId}`);
}

export function countViewAlbum (_id:AlbumId) {
    return ajax.post('/meta/do_counter_view/album/', {
        _id
    });
}

export function countViewArtist (_id:ArtistId) {
    return ajax.post('/meta/do_counter_view/artist/', {
        _id
    });
}

export function getArtistInfo (artistId:ArtistId) {
    return ajax.get<{
        info:ArtistModel,
        albums:AlbumModel[],
        articles:ArticleModel[],
        related_albums:AlbumModel[],
        style_hot:Record<GenreStyleId, number>
    }>(`/meta/artist/${artistId}`);
}

interface MetaSearchParams {
    page: number;
    search_info: string;
}

// meta search
export function metaSearchAlbums (params:MetaSearchParams) {
    return ajax.post<{
        pages:Pages;
        albums_artist:ArtistModel[];
        albums:AlbumModel[];
        albums_exact:AlbumModel[];
    }>('/meta/search/albums', params);
}

export function metaSearchArtists (params:MetaSearchParams) {
    return ajax.post<{
        pages:Pages;
        artists:ArtistModel[];
        artists_exact:ArtistModel[];
    }>('/meta/search/artists', params);
}

// discogs search
export interface DiscogsAlbums {
    _id:string;
    title:string;
    primary_img:string;
    year:string;
    api_link:string;
    thirdid:number;
    site:'discogs';
    styles:GenreStyleId[]
}

export function metaDiscogsSearchAlbums (params:MetaSearchParams) {
    return ajax.post<{
        albums:DiscogsAlbums[];
        albums_exact:[]
        pages:{
            total:number;
        }
    }>('/meta/discogs_search/albums', params);
}

export interface DiscogsArtist {
    '_id' :string,
    'name' :string,
    'primary_img' :string,
    'api_link' :string,
    'thirdid' :number,
    'site' : 'discogs',
    'styles' :GenreStyleId[],
}

export function metaDiscogsSearchArtists (params:MetaSearchParams) {
    return ajax.post<{
        artists:DiscogsArtist[];
        artists_exact:[]
        pages:{
            total:number;
        }
    }>('/meta/discogs_search/artists', params);
}

// netease search
export interface NeteaseAlbums {
    _id:string;
    title:string;
    primary_img:string;
    year:string;
    api_link:string;
    thirdid:number;
    site:'discogs';
    styles:GenreStyleId[]
}

export function metaNeteaseSearchAlbums (params:MetaSearchParams) {
    return ajax.post<{
        albums:DiscogsAlbums[];
        albums_exact:[]
        pages:{
            total:number;
        }
    }>('/meta/netease_search/albums', params);
}

export interface NeteaseArtist {
    '_id' :string,
    'name' :string,
    'primary_img' :string,
    'api_link' :string,
    'thirdid' :number,
    'site' : 'discogs',
    'styles' :GenreStyleId[],
}

export function metaNeteaseSearchArtists (params:MetaSearchParams) {
    return ajax.post<{
        artists:DiscogsArtist[];
        artists_exact:[]
        pages:{
            total:number;
        }
    }>('/meta/netease_search/artists', params);
}

export interface DoubanAlbum {
    '_id' : string,
    'title' :string,
    'primary_img' :string,
    'year' : number,
    'thirdid' :number,
    'site':'douban',
    'styles' : GenreStyleId[],
}

export function metaDoubanAlbums (params:MetaSearchParams) {
    return ajax.post<{
        albums:DoubanAlbum[];
        albums_exact:[];
        pages:{
            total:number;
        }
    }>('/meta/douban_search/albums', params);
}

export function metaDoCreateThird<T extends 'artist' | 'album'> (platform:string, type:T, id:string | number) {
    return ajax.post<Record<T, T extends 'artist'?ArtistModel:AlbumModel>>(`/meta/do_create_third/${platform}/${type}/${id}`);
}
export function metaDoCreateThirdArtist (thirdArtist:{
    site:string;
    thirdid:number | string;
}) {
    const url = '/meta/do_create_third/' + thirdArtist.site + '/';

    return ajax.post<{
        artist:ArtistModel
    }>(`${url}artist/${thirdArtist.thirdid}`, thirdArtist);
}

export function metaDoCreateThirdDiscogs<T extends 'artist' | 'album'> (type:T, id:string | number) {
    return ajax.post<Record<T, T extends 'artist'?ArtistModel:AlbumModel>>(`/meta/do_create_third_discogs/${type}/${id}`);
}

export function metaDoCreateThirdAlbum (thirdAlbum:{
    site:string;
    thirdid:number | string;
    artist_id?:string
}) {
    let url = '';
    if (thirdAlbum.site === 'discogs') {
        url = '/meta/do_create_third_discogs/';
    } else if (thirdAlbum.site === 'discogs_release') {
        url = '/meta/do_create_third_discogs_release/';
    } else {
        url = '/meta/do_create_third/' + thirdAlbum.site + '/';
    }

    return ajax.post<{
        album:AlbumModel
    }>(`${url}album/${thirdAlbum.thirdid}`, thirdAlbum);
}

export type QuickSearchAlbum = Pick<AlbumModel, '_id' |'title' | 'primary_img' | 'year' | 'main_artist' | 'hot_index'>
export type QuickSearchArtist = Pick<ArtistModel, '_id' | 'name' | 'primary_img'>

export function metaQuickSearch (type:string, query:string) {
    return ajax.post<{
        albums:QuickSearchAlbum[];
        artists:QuickSearchArtist[];
    }>(`/meta/quick_search/${type}`, {
        search_info: query
    });
}

export function albumRateHeart ({ album_id, ...data }:{
    album_id:AlbumId,
    rate:number
}) {
    return ajax.post<Pick<AlbumModel, 'hot_index' | 'counter_heart_1' | 'counter_heart_2'>>(`/meta/do_rate_heart/${album_id}`, data);
}

export function getMetaInfo<T extends 'artist' | 'album'> (type:T, id:T extends 'artist'? ArtistId:AlbumId) {
    return ajax.get<{
        info: T extends 'artist'?ArtistModel:AlbumModel
    }>(`/meta/info/${type}/${id}`);
}

export function doCombineAlbum (albumId:AlbumId, param:{combineId:string}) {
    return ajax.post(`/meta/do_combine_album/${albumId}`, param);
}

export function doCombineThird (albumId:AlbumId, param:{
    combineId:string,
    combineIdFrom: string,
    artistId:string
}) {
    return ajax.post(`meta/do_combine_thirdplat/album/${albumId}`, param);
}

export function doEditAlbum (albumId:AlbumId, param:AlbumModel) {
    return ajax.post<{
        info:AlbumModel
    }>(`/meta/do_edit_album/${albumId}`, param);
}

export function do_lock<T extends 'album' | 'artist'> (type:T, _id:T extends 'album'?AlbumId:ArtistId) {
    return ajax.post<{
        info:T extends 'album'? Pick<AlbumModel, 'quality'>:Pick<ArtistModel, 'quality'>
    }>(`/meta/do_lock/${type}`, {
        _id
    });
}

export function getMetaEditAlbum (albumId:AlbumId) {
    return ajax.get<{
        info:AlbumModel
    }>(`/meta/edit/album/${albumId}`);
}

export function getMetaEditArtist (artistId:ArtistId) {
    return ajax.get<{
        info:ArtistModel
    }>(`meta/edit/artist/${artistId}`);
}

export function doCombineArtist (artistId:ArtistId, param:{combineId:string}) {
    return ajax.post(`/meta/do_combine_artist/${artistId}`, param);
}

export function doCombineDiscogsArtist (artistId: ArtistId, param: {
    combineIdFrom: string,
    combineId: string
}) {
    return ajax.post<{
        info:ArtistModel
    }>(`/meta/do_combine_thirdplat/artist/${artistId}`, param);
}

export function doEditArtist (artistId:ArtistId, param:ArtistModel) {
    return ajax.post<{
        info: ArtistModel
    }>(`/meta/do_edit_artist/${artistId}`, param);
}
