
import { defineComponent, ref, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { RouterLink, useRouter } from 'vue-router';

import { useStore } from '@/store';
import Searchinput, { QuickSearchResult } from '@/components/common/SearchInput.vue';

export default defineComponent({
    components: {
        RouterLink,
        Searchinput
    },
    setup () {
        const scrollClass = ref('');

        const store = useStore();
        const router = useRouter();

        const onScroll = () => {
            const scrolled = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrolled > 55) {
                scrollClass.value = 'is_scroll_higher';
            } else {
                scrollClass.value = '';
            }
        };

        onMounted(() => {
            nextTick(() => {
                window.addEventListener('scroll', onScroll);
            });
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', onScroll);
        });

        const handerlSearch = (searchInfo:string) => {
            router.push({
                path: `/meta/search/${searchInfo}`
            });
        };

        const handerlSelect = (searchInfo:QuickSearchResult) => {
            if (searchInfo.type === 'artist') {
                router.push({
                    path: `/meta/artist/${searchInfo._id}`
                });
            } else {
                router.push({
                    path: `/meta/album/${searchInfo._id}`
                });
            }
        };

        return {
            scrollClass,
            isLogin: computed(() => store.state.isLogin),
            mainMenuActiveIndex: computed(() => store.state.mainMenuActiveIndex),
            menus: [
                {
                    route: {
                        name: 'Index'
                    },
                    label: '广场',
                    index: 'index'
                },
                {
                    route: {
                        path: '/rank'
                    },
                    label: '榜单',
                    index: 'rank'
                },
                {
                    route: {
                        path: '/meta/albums'
                    },
                    label: '专辑',
                    index: 'albums'
                },
                {
                    route: {
                        path: '/chronicle'
                    },
                    label: '编年史',
                    index: 'chronicle'
                },
                {
                    route: {
                        path: '/genres'
                    },
                    label: '图书馆',
                    index: 'genre'
                }
            ],
            handerlSearch,
            handerlSelect
        };
    }
});
