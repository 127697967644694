
import { defineComponent, ref, toRefs } from 'vue';
import { ElAutocomplete, ElInput } from 'element-plus';

import { metaQuickSearch, QuickSearchAlbum, QuickSearchArtist } from '@/services';

export type QuickSearchAlbumWithType = QuickSearchAlbum & {
    type: 'album'
}

export type QuickSearchArtistWithType = QuickSearchArtist & {
    type: 'artist'
}

export type QuickSearchResult = QuickSearchAlbumWithType | QuickSearchArtistWithType

type AutocompleteSelectType = InstanceType<typeof ElAutocomplete>['$props']['onSelect']

export default defineComponent({
    components: {
        ElAutocomplete,
        ElInput
    },
    props: {
        input: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'default'
        },
        type: {
            type: String,
            default: 'all'
        },
        showQuick: {
            type: Boolean,
            default: true
        }
    },
    emits: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        enterDoSearch (search:string) {
            return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selectQuickSearch (item:QuickSearchResult) {
            return true;
        }

    },
    setup (props, { emit }) {
        const {
            type
        } = toRefs(props);
        const inputValue = ref('');
        const loading = ref(false);
        const results = ref<QuickSearchResult[]>([]);

        const doSearch = () => {
            emit('enterDoSearch', inputValue.value);
        };

        // 因为autocomplete的select类型为(item: Record<string, any>) => any
        // 函数参数类型是 contravariant的，所以必须是Record<string,any>的父类型而不是子类型QuickSearchResult

        const handleSelect:AutocompleteSelectType = (item) => {
            emit('selectQuickSearch', item as QuickSearchResult);
        };

        const querySearchAsync = (query:string, cb: (data: QuickSearchResult[]) => void) => {
            loading.value = true;

            if (query.length < 4) {
                // eslint-disable-next-line n/no-callback-literal
                cb([]);
                return;
            }
            console.log(type.value);

            metaQuickSearch(type.value, query).then((json) => {
                results.value = [];
                if (type.value === 'all' || type.value === 'artists') {
                    json.data.artists.forEach((artist) => {
                        results.value.push({
                            ...artist,
                            type: 'artist'
                        });
                    });
                }
                if (type.value === 'all' || type.value === 'albums') {
                    json.data.albums.forEach((album) => {
                        results.value.push({
                            ...album,
                            type: 'album'
                        });
                    });
                }
                cb(results.value);
            }).finally(() => {
                loading.value = false;
            });
        };

        return {
            inputValue,
            doSearch,
            querySearchAsync,
            handleSelect
        };
    }
});

