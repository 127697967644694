import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import article from './article';
import home from './home';
import rank from './rank';
import chronicles from './chronicles';
import genres from './genres';
import meta from './meta';
import user from './user';

import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
    ...article,
    ...home,
    ...rank,
    ...chronicles,
    ...genres,
    ...meta,
    ...user
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    let gotoLoginPage = false;
    if (to.meta.requireLogin) {
        let isLogin = store.state.isLogin;
        if (!isLogin) {
            store.commit('checkLogin');
        }
        isLogin = store.state.isLogin;
        if (!isLogin) {
            gotoLoginPage = true;
        }
    }

    if (gotoLoginPage) {
        next({
            path: '/user/login',
            query: {
                redirect: to.fullPath
            }
        });
    } else {
        if (to.meta.menuIndex) {
            store.commit('setMenu', to.meta.menuIndex);
        }
        if (to.meta.title) {
            document.title = to.meta.title as string;
        }
        // 统计代码
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
        next();
    }
});

export default router;
