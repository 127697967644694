import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search_holder" }
const _hoisted_2 = {
  key: 0,
  class: "name"
}
const _hoisted_3 = {
  key: 1,
  class: "name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElAutocomplete = _resolveComponent("ElAutocomplete")!
  const _component_ElInput = _resolveComponent("ElInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showQuick)
      ? (_openBlock(), _createBlock(_component_ElAutocomplete, {
          key: 0,
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          size: _ctx.size,
          "suffix-icon": "el-icon-search",
          "fetch-suggestions": _ctx.querySearchAsync,
          placeholder: "请输入内容",
          style: {"width":"100%"},
          onSelect: _ctx.handleSelect,
          onKeyup: _withKeys(_ctx.doSearch, ["enter"])
        }, {
          default: _withCtx(({ item }) => [
            (item.type == 'album')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 专辑 " + _toDisplayString(item.main_artist.name) + " - " + _toDisplayString(item.title) + " [" + _toDisplayString(item.year) + "] ", 1))
              : _createCommentVNode("", true),
            (item.type == 'artist')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, " 艺术家 " + _toDisplayString(item.name), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "size", "fetch-suggestions", "onSelect", "onKeyup"]))
      : (_openBlock(), _createBlock(_component_ElInput, {
          key: 1,
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
          placeholder: "回车开始搜索",
          onKeyup: _withKeys(_ctx.doSearch, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"]))
  ]))
}