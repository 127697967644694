import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/user/login',
        name: 'Login',
        component: () => import('@/views/User/Login.vue'),
        meta: {
            requireLogin: false,
            noMenu: true,
            title: '登录 - 匿名旅行者'
        }
    },
    {
        path: '/user/register',
        name: 'Register',
        component: () => import('@/views/User/Register.vue'),
        meta: {
            requireLogin: false,
            noMenu: true,
            title: '登录 - 匿名旅行者'
        }
    },
    {
        path: '/my/info',
        name: 'My',
        component: () => import('@/views/User/My/Index.vue'),
        meta: {
            requireLogin: true,
            noMenu: true,
            title: '我的 - 匿名旅行者'
        }
    },
    {
        path: '/my/invites',
        name: 'Invites',
        component: () => import('@/views/User/InviteCode/Index.vue'),
        meta: {
            requireLogin: true,
            noMenu: true,
            title: '邀请 - 匿名旅行者'
        }
    },

    {
        path: '/user/profile/:id',
        name: 'Profiles',
        component: () => import('@/views/User/Profile/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'user',
            title: '用户 - 匿名旅行者'
        }
    }
];

export default routes;
