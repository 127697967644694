import ajax from '@/basics/fetch';

import { GenreStyleModel, GenreStyleType, GenreStyleId, ArtistModel } from '@/typings';

export type GenreStyleListItem = GenreStyleModel & {
    child_styles:GenreStyleModel[];
}

export function getGenreStyleList () {
    return ajax.get<{
        genres:Array<GenreStyleListItem>
    }>('/genres/index');
}

export function genreCatelog () {
    return ajax.post<{
        styles:Record<GenreStyleId, Omit<GenreStyleModel, 'type'>& {
            type:GenreStyleType
        }>;
        structure:Record<string, GenreStyleId[]>;
    }>('/genres/catelog');
}

export function genreInfo (idOrNamelink:string | GenreStyleId) {
    return ajax.get<{
        info:GenreStyleModel;
        childs:GenreStyleModel[];
        artists:ArtistModel[];
    }>(`/genres/genre/${idOrNamelink}`);
}

export function sendGenreCounterView (_id:GenreStyleId) {
    return ajax.post('/genres/do_counter_view/', {
        _id
    });
}

export function getGenreStyleInfo (name_link:string) {
    return ajax.get<{
        info:GenreStyleModel;
        artists:ArtistModel[];
        childs:GenreStyleModel[];
        origins_by:GenreStyleModel[];
    }>(`/genres/style/${name_link}`);
}

export function doLockGenre (_id:GenreStyleId) {
    return ajax.post<{
        info:GenreStyleModel
    }>('/genres/do_lock/genre', {
        _id
    });
}

export type EditGenreParam = Pick<GenreStyleModel, 'name' | 'name_alt' | 'type' | 'profile' | 'origins' | 'parents' | 'show_in_list'>

export function doEditGenre(_id: GenreStyleId | null, param: EditGenreParam) {
    const targetUrl = _id ? `/genres/do_edit_genre/${_id}` : `/genres/do_create_genre/`;
    return ajax.post<{
        info:GenreStyleModel
    }>(targetUrl, param);
}
