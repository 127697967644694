import { MediaModel } from '@/typings/';
import ajax from '@/basics/fetch';

export function getMedias () {
    return ajax.post<{
        medias:MediaModel[],
    }>('/media/medias/', {
        page: 0
    });
}
