import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/genres',
        name: 'Genres',
        component: () => import('@/views/Genre/GenreList/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '风格图书馆 - 匿名旅行者'
        }
    },
    {
        path: '/genres/catelog',
        name: 'Catelog',
        component: () => import('@/views/Genre/GenreCatelog/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '风格图书馆 - 匿名旅行者'
        }
    },
    {
        path: '/genres/genre/:name_link',
        name: 'Genre',
        component: () => import('@/views/Genre/GenreInfo/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '风格图书馆 - 匿名旅行者'
        }
    },
    {
        path: '/genres/style/:name_link',
        name: 'Style',
        component: () => import('@/views/Genre/GenreStyle/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'style',
            title: '风格图书馆 - 匿名旅行者'
        }
    }

];

export default routes;
