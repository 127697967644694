import { RouteRecordRaw } from 'vue-router';

const routes:Array<RouteRecordRaw> = [

    {
        path: '/article/rocknut/:article_id',
        name: 'Rocknuts',
        component: () => import('@/views/Article/Rocknut.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '摇滚坚果 - 匿名旅行者'
        }
    },
    {
        path: '/article/write/rocknuts/:album_id/:article_id',
        name: 'Rocknuts-Write',
        component: () => import('@/views/Article/RocknutsWrite.vue'),
        meta: {
            requireLogin: true,
            menuIndex: 'genre',
            title: '写文章 - 匿名旅行者'
        }
    },

    {
        path: '/article/interview/:article_id',
        name: 'Interview',
        component: () => import('@/views/Article/Interview.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '摇滚坚果 - 匿名旅行者'
        }
    },
    {
        path: '/article/write/interviews/:main_artist_id/:article_id',
        name: 'Interview-Write',
        component: () => import('@/views/Article/InterviewsWrite.vue'),
        meta: {
            requireLogin: true,
            menuIndex: 'genre',
            title: '写文章 - 匿名旅行者'
        }
    },

    {
        path: '/article/genrehis/:article_id',
        name: 'Genrehis',
        component: () => import('@/views/Article/Genrehis.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '摇滚坚果 - 匿名旅行者'
        }
    },
    {
        path: '/article/write/genrehis/:genre_id/:article_id',
        name: 'Genrehis-Write',
        component: () => import('@/views/Article/GenrehisWrite.vue'),
        meta: {
            requireLogin: true,
            menuIndex: 'genre',
            title: '写文章 - 匿名旅行者'
        }
    },

    {
        path: '/article/musicalls/:article_id',
        name: 'Musicall',
        component: () => import('@/views/Article/Musicall.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '摇滚坚果 - 匿名旅行者'
        }
    },
    {
        path: '/article/write/musicalls/:article_id',
        name: 'Musicall-Write',
        component: () => import('@/views/Article/MusicallWrite.vue'),
        meta: {
            requireLogin: true,
            menuIndex: 'genre',
            title: '写文章 - 匿名旅行者'
        }
    },

    {
        path: '/article/version_album/:article_id',
        name: 'VersionAlbum',
        component: () => import('@/views/Article/Version_album.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'rank',
            title: '榜单 - 匿名旅行者'
        }
    }

];

export default routes;
