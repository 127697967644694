import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'index',
            title: '首页 - 匿名旅行者'
        }
    }

];

export default routes;
