import ajax from "@/basics/fetch";

import { CommentModel } from "@/typings";
import { CommentTargetType } from "@/typings/comment";
export function getCommentList(
    targetType: CommentTargetType,
    targetIds: string,
    params: { page: number; scopeType: string },
) {
    return ajax.get<{
        comments: CommentModel[];
        pages: {
            total: number;
        };
    }>(`/comment/comments/${targetType}/${targetIds}`, { params });
}

export type CommentCreateInfo = Pick<
    CommentModel,
    "artist_id" | "album_id" | "rank_id" | "version_id" | "version_item"
>;

export type CommentCreateFormData = Pick<
    CommentModel,
    | "content"
    | "artist_id"
    | "album_id"
    | "rank_id"
    | "version_id"
    | "version_item"
    | "is_anon"
    | "is_private"
>;
export function doCreateComment(
    targetType: CommentTargetType,
    targetIds: string,
    info: CommentCreateInfo,
    data: CommentCreateFormData,
) {
    return ajax.post<{
        comment: CommentModel;
    }>(`/comment/do_create/${targetType}/${targetIds}`, { ...data, ...info });
}
