import { AxiosInstance } from "axios";

import wx from "weixin-js-sdk-ts";
function isWechat() {
    return /MicroMessenger/i.test(window.navigator.userAgent);
}
export default {
    _wx: wx,
    wxConfig: function (axios: AxiosInstance, share: any) {
        if (!isWechat()) {
            return;
        }
        if (!share.title) {
            share.title = "匿名旅行者";
        }
        if (!share.desc) {
            share.desc = "匿名旅行者 - 泛摇滚文化社区";
        }
        if (!share.link) {
            share.link = window.location.href;
        }
        if (!share.imgUrl) {
            share.imgUrl =
                "https://anontraveler.oss-cn-shanghai.aliyuncs.com/medias/anontraveler/logo.jpg";
        }
        axios
            .post("/api/wxsig", {
                system: "gj",
                url: window.location.href,
            })
            .then((json) => {
                wx.config({
                    // debug: true,
                    appId: json.data.appId,
                    timestamp: json.data.timestamp,
                    nonceStr: json.data.nonceStr,
                    signature: json.data.signature,
                    jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
                } as any);
                wx.ready(function () {
                    wx.updateAppMessageShareData(share);
                    wx.updateTimelineShareData(share);
                    console.log("set wx share!", share);
                });
            })
            .catch((e) => {
                console.log(e);
                alert(e.data.msg);
            });
    },
};
