import ajax, { fileUpload } from '@/basics/fetch';
import {
    AlbumModel,
    ArticleId,
    ArticleModel,
    GenreStyleId,
    Pages,
    RocknutModel,
    GenrehisModel,
    InterviewModel,
    ArtistModel,
    MusicallModel,
    ChronicleId,
    ChronicleItemModel,
    VersionAlbumModel,
    AlbumId
} from '@/typings';

export function doSendCounterView (article:ArticleModel) {
    // url中的那个参数后端没用到，所以直接传枚举了
    return ajax.post<{
        counter_view:number
    }>(`/article/do_counter_view/${article.article_type.index}/`, {
        _id: article._id
    });
}

export function getArticles (page:number) {
    return ajax.post<{
        articles:ArticleModel[],
        pages:{
            total:number;
            perPage:number;
            pageNow:string;
        },
    }>('/article/articles', {
        page
    });
}

// TODO 统计访问量的 后端根本没用到 year 或者 article 这个最后一个参数
export function doCounterViewYear (_id:ArticleId) {
    return ajax.post<{
        counter_view:number
    }>('/article/do_counter_view/year/', {
        _id
    });
}

export function doCounterViewArticle (_id:ArticleId) {
    return ajax.post<{
        counter_view:number
    }>('/article/do_counter_view/article', {
        _id
    });
}

export function getGenreArticles (genreStyleId:GenreStyleId, params:{page:number}) {
    return ajax.post<{
        articles:ArticleModel[],
        pages:Pages
    }>(`/article/articles/${genreStyleId}`, params);
}

export function getArticleRocknut (rocknutId:ArticleId) {
    return ajax.get<{
        article:RocknutModel;
        album:AlbumModel
    }>(`/article/rocknut/${rocknutId}`);
}
export function getArticleVersionAlbum (articleId:ArticleId) {
    return ajax.get<{
        article:VersionAlbumModel;
        album:AlbumModel
    }>(`/article/version_album/${articleId}`);
}

export function getArticleRelatedArticle (article_id:ArticleId, param:{page:number}) {
    return ajax.post<{
        articles:ArticleModel[];
        pages:Pages
    }>(`/article/article_related/${article_id}`, param);
}

export function getArticleGenrehis (genrehisId:ArticleId) {
    return ajax.get<{
        article:GenrehisModel
    }>(`/article/genrehis/${genrehisId}`);
}

export function getArticleInterview (interviewId:ArticleId) {
    return ajax.get<{
        article:InterviewModel;
        artist:ArtistModel;
    }>(`/article/interview/${interviewId}`);
}

export function getArticleMusical (musicalId:ArticleId) {
    return ajax.get<{
        article:MusicallModel
    }>(`/article/musicall/${musicalId}`);
}

export function createArticleDraft ({ type, ...param }:{
    reference_id:string;
    type: 'musicall' | 'genrehis' | 'chronicles' | 'rocknuts' | 'interviews' | 'rankversion'
}) {
    return ajax.post<{
        article_id:ArticleId
    }>(`/article/write/${type}`, param);
}

export function unpublishArticle (_id:ArticleId) {
    // TODO url最后一个参数后端没用到
    return ajax.post('/article/do_unpublish/genrehis', {
        _id
    });
}
export type ArticleType = 'genrehis' | 'interviews' | 'musicall' | 'rocknuts' | 'chronicleyear'
// TODO 类型
export function saveArticle (type:ArticleType, article:ArticleModel) {
    return ajax.post<{
        article:ArticleModel
    }>(`/article/do_save/${type}`, article);
}

export function publishArticle (type:ArticleType, article:ArticleModel) {
    return ajax.post<{
        article:ArticleModel
    }>(`/article/do_publish/${type}`, article);
}

export function uploadArticle (data:FormData) {
    return fileUpload<{
        url:string
    }>('/article/do_upload/', data);
}

export function doUnPublishChronicleYear (_id:ArticleId) {
    return ajax.post('/article/do_unpublish/chronicleyear', {
        _id
    });
}

export function articleRemoveSubChronicleItem (param:{
    main_article_id:ArticleId,
    item_id:ArticleId
}) {
    return ajax.post('/article/remove_sub/chronicle_item', param);
}

export function articleWriteSubChronicleItem (param:{
    main_article_id:ArticleId,
    chronicle_id:ChronicleId,
    year:string
}) {
    return ajax.post<{
        article_info:ChronicleItemModel
    }>('/article/write_sub/chronicle_item', param);
}

export function articleWriteSubVersionItemAlbum (param:Pick<VersionAlbumModel, 'main_rank_id' | 'content'>&{
    reference_id:AlbumId,
    main_article_id:ArticleId
}) {
    return ajax.post<{
        article_id:ArticleId
    }>('/article/write_sub/version_item_album', param);
}

export function articleRemoveSubVersionItemAlbum (param:{
    main_article_id:ArticleId,
    item_id:ArticleId
}) {
    return ajax.post('/article/remove_sub/version_item_album/', param);
}

export function articleDoSaveRankVersion (param:any) {
    return ajax.post('/article/do_save/rankversion/', param);
}

export function articleDoPublishRankVersion (param:any) {
    return ajax.post<{
        article:ArticleModel
    }>('/article/do_publish/rankversion', param);
}

export function doUnpublishRankversion (_id:ArticleId) {
    return ajax.post('/article/do_unpublish/rankversion', {
        _id
    });
}
