const setWechatTitle = function (title:string | undefined, img :string | null) {
    if (title === undefined || window.document.title === title) {
        return;
    }
    document.title = title;
    const mobile = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(mobile)) {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        // 替换成站标favicon路径或者任意存在的较小的图片即可，支付宝小程序引用默认空白的base64图片会有安全警告
        const _img = /alipay/.test(mobile) ? img : (img || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
        _img && iframe.setAttribute('src', _img);
        const iframeCallback = function () {
            setTimeout(function () {
                iframe.removeEventListener('load', iframeCallback);
                document.body.removeChild(iframe);
            }, 0);
        };
        iframe.addEventListener('load', iframeCallback);
        document.body.appendChild(iframe);
    }
};
export default function (el:HTMLElement, binding:{value:string}) {
    setWechatTitle(binding.value, el.getAttribute('img-set') || null);
}
