import ajax from '@/basics/fetch';

import { RankModel, GenreStyleId, RankVersion, Pages, RankId, AlbumModel, AlbumId, VersionAlbumModel, RankItemModel, RankUserVoteModel, ArticleId, ArticleModel, ArtistModel, RankItemId, RankUserVoteId } from '@/typings';

export function getRankList ({
    genre,
    topic,
    ...params
}:{
    genre:GenreStyleId;
    topic:number;
    page:number;
}) {
    return ajax.post<{
        ranks:RankModel[],
        pages:{
            total:number;
        }
    }>(`/rank/ranks/${genre}/${topic}`, params);
}

export function getRankHotVersions ({
    genre,
    ...params
}:{
    genre:GenreStyleId,
    page:number,
}) {
    return ajax.post<{
        articles:RankVersion[];
        pages:Pages
    }>(`/rank/hot_versions/${genre}`, params);
}

export function getRankInfo (genreStyleId:GenreStyleId, params:{page:number}) {
    return ajax.post<{
        ranks:RankModel[];
        pages:Pages
    }>(`/rank/ranks/${genreStyleId}`, params);
}

export type RankDetailItem = RankItemModel& {
    main_album:AlbumModel
}

export function getRankDetail (id:RankId) {
    return ajax.get<{
        info:RankModel;
        items:RankDetailItem[];
    }>(`/rank/rank/${id}`);
}

export function getRankVersions(id: RankId, params: { page: number }) {
    return ajax.get<{
        articles: RankVersion[];
        pages: Pages;
    }>(`/rank/versions/${id}?page=${params.page}`);
}

export function getRankRemarks (id:RankId, ref_ids:AlbumId[]) {
    return ajax.post<{
        remarks:Record<AlbumId, VersionAlbumModel[]>
    }>(`/rank/remarks/${id}`, {
        ref_ids
    });
}

export function getRankVotes (id:RankId) {
    return ajax.get<{
        votes:RankUserVoteModel[]
    }>(`/rank/votes/${id}`);
}

// TODO 这里数据结构有点问题 似乎应该是更具体的Article
export type RankVersionItem = ArticleModel & {
    main_album:AlbumModel;
    rank_order:number;
    main_artist_id:ArtistModel
}

export function getRankVersion (articleId:ArticleId) {
    return ajax.get<{
        article:RankVersion;
        items:RankVersionItem[];
    }>(`/rank/version/${articleId}`);
}

export function getRankVersionRelatedArticle (articleId:ArticleId, rankId:RankId) {
    return ajax.post<{
        articles:ArticleModel[];
    }>(`/rank/versions_related/${articleId}/${rankId}`);
}

export type RankCreateFormData = Pick<RankModel, 'title' | 'desc' | 'styles' | 'primary_img' | 'type' | 'topic'>
export function doCreateRank (data:RankCreateFormData) {
    return ajax.post<{
        rank:RankModel
    }>('/rank/do_create_rank/', data);
}

export function rankUpvote (id:RankItemId) {
    return ajax.post<{
        rank_item:RankItemModel;
        vote_id:RankUserVoteId;
    }>(`/rank/do_upvote/${id}`);
}

export function rankCancelUpvote (id:RankItemId) {
    return ajax.post<{
        rank_item:RankItemModel;
    }>(`/rank/do_upvote_cancel/${id}`);
}
