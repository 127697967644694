import { RouteRecordRaw } from 'vue-router';

const routes:Array<RouteRecordRaw> = [
    {
        path: '/meta/albums/',
        name: 'Albums',
        component: () => import('@/views/Meta/MetaAlbums/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '专辑 - 匿名旅行者'
        }
    },
    {
        path: '/meta/artists',
        name: 'Artists',
        component: () => import('@/views/Meta/MetaArtists/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '艺术家 - 匿名旅行者'
        }
    },
    {
        path: '/meta/album/:id',
        name: 'Album',
        component: () => import('@/views/Meta/MetaAlbum/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '专辑 - 匿名旅行者'
        }
    },
    {
        path: '/meta/artist/:id',
        name: 'Artist',
        component: () => import('@/views/Meta/MetaArtist/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '艺人 - 匿名旅行者'
        }
    },
    {
        path: '/meta/search/:searchInfo?',
        name: 'Search',
        component: () => import('@/views/Meta/Search/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'genre',
            title: '搜索 - 风格图书馆 - 匿名旅行者'
        }
    }
];

export default routes;
