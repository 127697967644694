
import { defineComponent } from 'vue';
import { ElConfigProvider } from 'element-plus';
import { MenuBar } from '@/components/App';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { useStore } from './store';
import { userDoRefresh } from './services';
import ajax from '@/basics/fetch';
import wx from '@/basics/wx';
import wechatTitle from '@/directives/wechat-title';
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {
        MenuBar,
        ElConfigProvider
    },
    directives: {
        wechatTitle
    },
    setup () {
        const store = useStore();
        store.commit('checkLogin');
        store.commit('loadAlbumCache');
        userDoRefresh().then((json) => {
            store.commit('doLoginToken', json.data);
        }).catch(() => {
            store.commit('doLogout');
        });

        const route = useRoute();

        wx.wxConfig(ajax, {});

        return {
            locale: zhCn,
            route
        };
    }
});
