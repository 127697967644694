import { UserInviteModel } from './../typings/user_invites';
import ajax from '@/basics/fetch';
import { UserId, AlbumModel, UserModel, ArticleModel, Pages, RankVersion } from '@/typings';

export function getUserAlbums (userId:UserId) {
    return ajax.get<{
        albums:AlbumModel[]
    }>(`/user/albums/${userId}`);
}

export function getUserAndArticle (userId:UserId) {
    return ajax.get<{
        info:UserModel,
        articles:ArticleModel[],
        pages:{
            total:number;
        };
    }>(`/user/user/${userId}`);
}

export type MyInvitesItem = Pick<UserInviteModel, '_id' | 'invite_code' | 'target_id' | 'type'>

export function myInvites () {
    return ajax.get<{
        invites:MyInvitesItem[]
    }>('/my/invites/');
}

export type MyInfo = Pick<UserModel, '_id' | 'name' | 'type' | 'counter_view' | 'counter_refrence' | 'counter_rank' | 'hot_index' | 'counter_rank_start'>

export function myInfo () {
    return ajax.get<{
        info:MyInfo
    }>('/my/info');
}

export function userDoLogout () {
    return ajax.get('user/doLogout');
}

export interface TokenInfo {
    token:string;
    expire_ts:number;
    refresh_ts:number;
}

export type LoginUserResponse = Pick<UserModel, '_id' | 'name' | 'type'>;

export interface LoginErrorResponse {
    field:'phone' | 'pwd';
    msg:string;
}

export interface LoginSuccessResponse {
    user_info:LoginUserResponse;
    token_info:TokenInfo
}

export function userDoLogin (param:{
    phone:string;
    pwd:string;
}) {
    return ajax.post<LoginSuccessResponse>('/user/do_login', param);
}

export interface RegErrorResponse {
    field:'phone' | 'pwd' | 'pwd2' |'name' | 'invite_code';
    msg:string;
}

export function userDoReg (data:{
    phone: string;
    pwd: string;
    pwd2: string;
    name: string;
    invite_code: string;
}) {
    return ajax.post<LoginSuccessResponse>('/user/do_reg', data);
}

export function myRankVerions () {
    return ajax.get<{
        articles:RankVersion[],
        pages:Pages
    }>('/my/rank_verions/');
}

export function userDoRefresh () {
    return ajax.get<LoginSuccessResponse>('/user/do_refresh');
}
