import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, storeKey } from './store';
import './assets/scss/main.scss';
import { ElLoading } from 'element-plus';

// Markdown 编辑器
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';

// icon element-plus有bug 比如Autocomplete的search icon 默认的search icon 就没显示出来
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(`ElIcon${key}`, component);
}
app.use(mavonEditor);
// app.use(ElementPlus)
app.use(store, storeKey);
app.use(router);
app.use(ElLoading);

app.mount('#app');
