import ajax from '@/basics/fetch';

import { GenreStyleId, ChronicleModel, ChronicleId, ChronicleYearModel, AlbumModel, ArtistModel, ChronicleItemModel, ArticleId } from '@/typings';

export function getChronicles ({
    genre,
    ...params
}:{
    genre:GenreStyleId,
    page:number;
}) {
    return ajax.post<{
        chronicles:ChronicleModel[],
        pages:{
            total:number;
        }
    }>(`/chronicle/chronicles/${genre}`, params);
}

export function getChronicleDetail (id:ChronicleId) {
    return ajax.get<{
        info:Pick<ChronicleModel, '_id' | 'begin_year' | 'end_year' | 'desc' | 'primary_img' | 'title' | 'styles'>
        albums:AlbumModel[],
        artists:ArtistModel[],
        years:Pick<ChronicleYearModel, '_id' | 'article_type' | 'begin_year' | 'brief' | 'content' | 'end_year' | 'is_orig' | 'is_published' | 'main_chronicle_id' | 'styles' | 'title' | 'ts_published' | 'ts_updated' | 'user_created' | 'years'>[]
    }>(`/chronicle/chronicle/${id}`);
}

export function getChronicleHighlights (id:ChronicleId) {
    return ajax.get<{
        items:Pick<ChronicleItemModel, '_id' | 'album_id' | 'article_type' | 'artist_id' | 'content' | 'counter_view' | 'is_published' | 'main_album' | 'main_artist_id' | 'main_year_id' | 'rank_order' | 'rank_score' | 'title' | 'ts_published' | 'ts_updated' | 'user_created' | 'year' >[]
    }>(`/chronicle/highlights/${id}`);
}

export type ChronicleDetailRes = Awaited<ReturnType<typeof getChronicleDetail>>['data']
export type ChronicleHighlights = Awaited<ReturnType<typeof getChronicleHighlights>>['data']['items']

export function getChronicleYear (id:ArticleId) {
    return ajax.get<{
        article:ChronicleYearModel;
        items:ChronicleItemModel[];
    }>(`/chronicle/year/${id}`);
}

export type CreateChronicleParam = Pick<ChronicleModel, 'title' | 'desc' | 'styles' | 'begin_year' | 'end_year'>

export function doCreateChronicle (param:CreateChronicleParam) {
    return ajax.post('/chronicle/do_create_chronicle/', param);
}

export type CreateChronicleYearParam = Pick<ChronicleYearModel, 'title' | 'brief' | 'begin_year' | 'end_year' | 'styles'>

export function doCreateChronicleYear (chronicleId:ChronicleId, param:CreateChronicleYearParam) {
    return ajax.post(`/chronicle/do_create_chronicle_year/${chronicleId}`, param);
}

export type EditChronicleParam = Pick<ChronicleModel, '_id' | 'title' | 'desc' | 'primary_img' | 'styles' | 'begin_year' | 'end_year'>

export function doEditChronicle (chronicleId:ChronicleId, param:EditChronicleParam) {
    return ajax.post<{
        info:ChronicleDetailRes['info']
    }>(`/chronicle/do_edit_chronicle/${chronicleId}`, param);
}
