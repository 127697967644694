import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/chronicle',
        name: 'Chronicles',
        component: () => import('@/views/Chronicle/Chronicles/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'chronicle',
            title: '编年史 - 匿名旅行者'
        }
    },
    {
        path: '/chronicle/chronicle/:chronicle_id',
        name: 'Chronicle',
        component: () => import('@/views/Chronicle/Chronicle/Index.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'chronicle',
            title: '编年史 - 匿名旅行者'
        }
    },
    {
        path: '/chronicle/chronicle/:chronicle_id/:year_id',
        name: 'Year',
        component: () => import('@/views/Chronicle/Chronicle/Year.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'chronicle',
            title: '榜单 - 匿名旅行者'
        }
    },
    {
        path: '/chronicle/chronicle/:chronicle_id/:year_id/write',
        name: 'YearWrite',
        component: () => import('@/views/Chronicle/Chronicle/YearWrite.vue'),
        meta: {
            requireLogin: false,
            menuIndex: 'chronicle',
            title: '榜单 - 匿名旅行者'
        }
    }

];

export default routes;
